import React from 'react'
import hero from '../images/fb-cover.jpg'

function Hero() {
  return (
    <>
       <div className="hero-section">
        <div className="image-wrapper"><img src={hero} alt="Remotefolks community" /></div>
       </div>
    </>
 
  )
}

export default Hero