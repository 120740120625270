import React from 'react'
import logo from '../svgs/logo.svg'

function Header() {
  return (
    <>
     <div className='rf-header flex'>
        <div>
          <div className='logo-column'> 
          <div className='logo-wrapper'>
            <h1> </h1>
            <img src={logo} alt="" /></div>
           </div>
          <div className='menu-column'></div>
          <div className='cta-column'></div>
        </div>
     </div>
    </>
   
  )
}

export default Header